import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import "../../components/prizes.css"
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"
// images

const SolardesalinationRn2 = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Solar Desalination Prize Round 1</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                In April of 2020, the Solar Energy Technologies Office at the
                U.S. Department of Energy (DOE) launched the Solar Desalination
                Prize competition to help achieve the goals of the{" "}
                <a href="https://www.energy.gov/eere/water-security-grand-challenge">
                  Water Security Grand Challenge.
                </a>
              </p>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                Round 1 quarterfinalists and semifinalists have been selected
                and are eligible to compete in the Design Contest.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.energy.gov/eere/water-security-grand-challenge">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp"  alt="stick" className="stick" loading="eager" />
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <p className="pcol-fz">
            The Solar Desalination Prize is a four-phase prize competition that
            will advance thermal desalination technologies to expand the
            utilization of non-traditional water sources, ensure water security,
            and improve the resilience of U.S. infrastructure.
          </p>
          <h1 className="mac_h1">The Prize Structure</h1>
          <div>
            <div className="row rww">
              <div className="col pcol-fz">
                <p>
                  The Solar Desalination Prize has a rapid iteration prize
                  structure designed to help entrepreneurs use innovative
                  research to come up with ideas, then design and test concepts,
                  with the end goal of having shovel-ready technology primed for
                  industry adoption.
                </p>
                <p>
                  Millions of dollars in prizes will be awarded over the
                  competition, increasing in value in each phase, culminating in
                  a $1 million grand prize at the end of the competition for
                  successful testing and demonstration of promising solar
                  desalination prototypes.
                </p>
              </div>
              <div className="col">
                <StaticImage
                  src="../../images/solardes.webp"
                  className="img-styl"
                  alt="solardes"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h2 className="mac_h1">Teaming Contest</h2>
          <p className="pcol-fz">
            These teams have taken steps to include innovative technology
            developers; established business relationships with key vendors and
            end users; and identified a possible testing location.
          </p>
          <hr />
          <p>
            View the details about each semifinalist team from the Teaming
            Contest (listed alphabetically by team).
          </p>
          <div className="card card-card">
            <div className="card-body">
              <h2>BrineZero</h2>
              <h5>Hopewell, NJ</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34780"
                target="_blank"
                className="has-text-bright"
              >
                <b>Brine Concentration with Managed Salt Precipitatation</b>
              </a>
              <p className="pcol-fz">
                This team, led by AIL Research, is developing a membrane
                desalination system capable of handling very-high-salinity water
                and using recovered heat to preheat the brine feed. Their system
                will be designed to pre-crystallize readily precipitating ions,
                such as magnesium and calcium, before the brine contacts the
                membrane, to prolong membrane lifetime. Managing the
                precipitation of salt and using recovered heat allows more water
                recovery from high-salinity brines. The team will focus their
                technology on the production of agricultural water for regions
                of the country where brackish groundwater is too saline to be
                used directly. They plan to install their prototype at Bell
                Ranch, in California, in partnership with Global Water
                Innovations.
              </p>
              <Button
                color="primary"
                id="toggler"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/J5FpPup_nh4?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <div className="card card-card">
            <div className="card-body">
              <h2>Connecticut Center for Applied Separations Technology</h2>
              <h5>Storrs, CT</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34785"
                className="has-text-bright"
              >
                <b>Membrane Distillation with Ceramic Membranes</b>
              </a>
              <p className="pcol-fz">
                This team, led by AIL Research, is developing a membrane
                desalination system capable of handling very-high-salinity water
                and using recovered heat to preheat the brine feed. Their system
                will be designed to pre-crystallize readily precipitating ions,
                such as magnesium and calcium, before the brine contacts the
                membrane, to prolong membrane lifetime. Managing the
                precipitation of salt and using recovered heat allows more water
                recovery from high-salinity brines. The team will focus their
                technology on the production of agricultural water for regions
                of the country where brackish groundwater is too saline to be
                used directly. They plan to install their prototype at Bell
                Ranch, in California, in partnership with Global Water
                Innovations.
              </p>
              <Button
                color="primary"
                id="toggler2"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler2">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/Pux5zwDyg9Y?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <div className="card card-card">
            <div className="card-body">
              <h2>Espiku</h2>
              <h5>Corvallis, OR</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34795"
                className="has-text-bright"
              >
                <b>
                  Selective Condensation and Evaporation Using Precise
                  Temperature Regulation (SCEPTER) ZLD
                </b>
              </a>
              <p className="pcol-fz">
                This team plans to advance and commercialize technology
                developed at Oregon State University and Michigan State
                University based on a humidification-dehumidification process
                that can recover clean water from water produced by oil and gas
                extraction. A novel cyclone separator, which can be easily
                designed at small scale for portability, separates salt from
                humid air and enables the process. By separating humid air from
                solid salt particles, this technology could be a cost- effective
                method to achieve zero-liquid discharge (ZLD). The team will
                design their pilot to be installed at the Bureau of
                Reclamation’s Brackish Groundwater National Desalination
                Research Facility (BGNDRF).
              </p>
              <Button
                color="primary"
                id="toggler3"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler3">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/x3hi7hdrWeU?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <div className="card card-card">
            <div className="card-body">
              <h2>GreenBlu</h2>
              <h5>Trenton, NJ</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34791"
                className="has-text-bright"
              >
                <b>Zero Discharge Multi-effect Adsorption Distiller</b>
              </a>
              <p className="pcol-fz">
                This team plans to develop a prototype pilot plant of a
                zero-liquid discharge solar-thermal desalination system with
                mineral recovery using its multi-effect adsorption distillation
                technology. The concept has the potential to be much more
                thermally efficient, and require simpler operation and
                maintenance, relative to conventional distillation technology.
                This team has forged partnerships with key vendors and will be
                utilizing a solar collector field developed by Hyperlight, in
                Brawley, CA, to host their pilot.
              </p>
              <Button
                color="primary"
                id="toggler4"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler4">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/_9P8qF_8tpg?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <div className="card card-card">
            <div className="card-body">
              <h2>Katz Water Technologies</h2>
              <h5>Houston, TX</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34762"
                className="has-text-bright"
              >
                <b>X-Vap: Self- Contained Distillation</b>
              </a>
              <p className="pcol-fz">
                This team redesigning its self-contained, compact distillation
                system to use solar-thermal energy instead of natural gas. The
                new design blends the existing multi-stage thermal distillation
                technology into a simpler design with the multi-stage process
                occurring in a single closed-loop heat exchanger. The technology
                has the potential to be a modular, portable system that can
                supply fresh water where needed. Prototype site testing is being
                planned at the Bureau of Reclamation’s Brackish Groundwater
                National Desalination Research Facility (BGNDRF), with a
                particular focus on the oil and gas production market.
              </p>
              <Button
                color="primary"
                id="toggler5"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler5">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/xc2ZtqVEqmA?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <div className="card card-card">
            <div className="card-body">
              <h2>Solar Desalt</h2>
              <h5>Houghton, MI</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34802"
                className="has-text-bright"
              >
                <b>Sorption-Based ZLD Technology</b>
              </a>
              <p className="pcol-fz">
                Sorption-Based ZLD Technology This team is a group of
                researchers from Michigan Technical University and Oak Ridge
                National Laboratory have teamed with solar collector suppliers
                to integrate standard multiple-effect distillation (MED)
                technology with a high-temperature desorption process and a
                low-temperature crystallization process to achieve zero liquid
                discharge (ZLD). The team has identified a test site in Arizona
                provided by the solar collector supplier Rackam and is
                collaborating with a waste-water treatment plant as a potential
                customer for the produced desalinated water.
              </p>
              <Button
                color="primary"
                id="toggler6"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler6">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/2ztGqIA615s?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <div className="card card-card">
            <div className="card-body">
              <h2>STREED</h2>
              <h5>Houston, TX</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34796"
                className="has-text-bright"
              >
                <b>Resonant Energy Exchange Desalination</b>
              </a>
              <p className="pcol-fz">
                This team is led by researchers from Rice University has
                developed a nanoparticle-based high-efficiency, low-maintenance
                solar-heated membrane distillation technology. The team will
                design a prototype of their nano-photonics solar absorber
                desalination system in combination with thermal energy storage.
                The team plans to develop a scalable system design that uses a
                spiral-wound architecture to minimize the plant’s physical
                footprint, which will be located at either BGNDRF or at a
                development near Corpus Christi, TX to provide fresh water.
              </p>
              <Button
                color="primary"
                id="toggler7"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler7">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/klavqlKRKw4?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <div className="card card-card">
            <div className="card-body">
              <h2>The HIT Team</h2>
              <h5>Rohnert Park, CA</h5>
              <hr />
              <a
                href="https://www.herox.com/SolarDesalinationRound1/round/808/entry/34793"
                class="has-text-bright"
              >
                <b>Switchable Solvent Water Extraction from Brine</b>
              </a>
              <p className="pcol-fz">
                This team includes researchers from Idaho National Laboratory
                and Trevi Systems is developing a solar-thermal-powered
                liquid/liquid extraction process that removes “hard” ions, like
                calcium and magnesium, before brine is treated by reverse
                osmosis, a conventional desalination process. This method could
                significantly improve the efficiency of reverse osmosis by
                allowing the process to operate over wider brine input
                conditions and requiring less maintenance to remove the scale
                caused by hard ions. The team has formed partnerships with Idaho
                National Labs, Artic Solar and Global Water Innovation to
                develop and test their prototype at Bell Ranch, in California,
                to target the agricultural water market.
              </p>
              <Button
                color="primary"
                id="toggler8"
                style={{ marginBottom: "1rem" }}
              >
                Watch it here
              </Button>
              <UncontrolledCollapse toggler="#toggler8">
                <div>
                  <iframe
                    width="100%"
                    height="444px"
                    src="https://www.youtube.com/embed/x3hi7hdrWeU?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Innovation Contest</h1>
          <div className="row rw">
            <div className="col">
              <p className="pcol-fz">
                These teams have taken steps to determine the technical
                feasibility, scalability, and other potential benefits of the
                proposed solution and articulated a credible pathway to
                commercialization.
              </p>
            </div>
            <div className="col">
              <StaticImage
                src="../../images/Solarwinnermap.jpg"
                className="img-styl"
                alt="solar winner map"
                loading="lazy"
              />
            </div>
          </div>
          <hr />
          <p>
            View the details about each quarterfinalist team from the Innovation
            Contest (listed alphabetically by team).
          </p>
        </div>
        <div className="container">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>team</th>
                <th className="tds">location</th>
                <th className="tds">entry title</th>
                <th className="has-text-centered">video</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>BrineZero</b>
                </td>
                <td className="tds">Hopewell, NJ</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29304"
                    className="has-text-bright"
                  >
                    Brine Concentration with Managed Salt Precipitates
                  </a>
                </td>
                <td className="has-text-centered">
                  <Button
                    color="primary"
                    id="tog"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/rLKFbVpuKzc?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>CCR</b>
                </td>
                <td className="tds">Frisco, TX</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/26204"
                    className="has-text-bright"
                  >
                    CCR’s LTDis Technology, Creating a Solution
                  </a>
                </td>
                <td className="has-text-centered">
                  <Button
                    color="primary"
                    id="tog2"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog2">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/lLh1MQxbYe4?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Connecticut Center for Applied Separation Technology</b>
                </td>
                <td className="tds">Storrs, CT</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/28899"
                    className="has-text-bright"
                  >
                    Membrane Distillation with Ceramic Membranes
                  </a>
                </td>
                <td className="has-text-centered">
                  <Button
                    color="primary"
                    id="tog3"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog3">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/Y_kD5nC9Hio?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>{" "}
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Element 16</b>
                </td>
                <td className="tds">Arcadia, CA</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29576"
                    className="has-text-bright"
                  >
                    Heat Storage for 24 Hour Desalination
                  </a>
                </td>
                <td className="has-text-centered">
                  <Button
                    color="primary"
                    id="tog4"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog4">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/0VKrD1h6oEE?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Team Epiphany</b>
                </td>
                <td className="tds">Pittsburgh, PA</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/25742"
                    className="has-text-bright"
                  >
                    100% Solar Trans-Critical Water Desalination
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog18"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog18">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/x5ve2RVGVTk?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>GreenBlu</b>
                </td>
                <td className="tds">Trenton, NJ</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29463"
                    class="has-text-bright"
                  >
                    Zero Discharge Multi-effect Adsorption Distiller
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog5"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog5">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/x5ve2RVGVTk?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Hard Ion Team</b>
                </td>
                <td className="tds">Rohnert Park, CA</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/27852"
                    class="has-text-bright"
                  >
                    Switchable Solvent Water Extraction from Brine
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog6"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog6">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/mwGfA2SrnwM?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Hawaii 5-O</b>
                </td>
                <td className="tds">Rohnert Park, CA</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29477"
                    class="has-text-bright"
                  >
                    Forward Osmosis: Biomimicry at Work
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog7"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog7">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/pMAQCpte5NI?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Katz Water Technologies</b>
                </td>
                <td className="tds">Houston, TX</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/27199"
                    class="has-text-bright"
                  >
                    X-Vap: Self-Contained Distillation
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog8"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog8">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/N8PvkIM8_p8?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>NoAir</b>
                </td>
                <td className="tds">Gainesville, FL</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29543"
                    class="has-text-bright"
                  >
                    NoAir: Super-Vacuum Gap Nanomembrane Distillation
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog9"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog9">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/HdtmLbSMX8g?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Pluvia</b>
                </td>
                <td className="tds">Los Angeles, CA</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/28800"
                    class="has-text-bright"
                  >
                    Mimicking Rain Cycle Using Traveling Liquid Beads
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog10"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog10">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/yFCpYgaICWA?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Potent Solar Potables</b>
                </td>
                <td className="tds">Andover, MA</td>{" "}
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29435"
                    class="has-text-bright"
                  >
                    Modular, Inexpensive Solar Thermal Evaporative Desal
                    (MISTED)
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog11"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog11">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/_SQUhpU1nFI?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>SCEPTER</b>
                </td>
                <td className="tds">Corvallis, OR</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29480"
                    class="has-text-bright"
                  >
                    Portable Treatment of Oil/Gas Wastewater with ZLD
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog12"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog12">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/VmGlJS_1ztA?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>SIP Solutions Team</b>
                </td>
                <td className="tds">Santa Barbara, CA</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29516"
                    class="has-text-bright"
                  >
                    TSSE: Desalination of Hypersaline Brines
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog13"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog13">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/RTU1mHGGyLU?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Solar Thermal Enhanced Evaporation</b>
                </td>
                <td className="tds">Minneapolis, MN</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29553"
                    class="has-text-bright"
                  >
                    Solar Thermal Enhanced Evaporation
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog14"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog14">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/wkv_ECp3o90?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Sorption-Based ZLD Technology</b>
                </td>
                <td className="tds">Houghton, MI</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29487"
                    class="has-text-bright"
                  >
                    Sorption-based ZLD Technology
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog15"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog15">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/c0erkw2DFXE?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>STREED Team @ Rice</b>
                </td>
                <td className="tds">Houston, TX</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29472"
                    class="has-text-bright"
                  >
                    STREED: Resident Energy Exchange Desalination
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog16"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog16">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/C-jvLOnKj8w?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Urban Solar-Desal Building</b>
                </td>
                <td className="tds">College Park, MD</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/28903"
                    class="has-text-bright"
                  >
                    Urban Solar-Desal Building
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog17"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog17">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/v1Yjf-bTc_E?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
              <tr>
                <td>
                  <b>Vanderbilt</b>
                </td>
                <td className="tds">Nashville, TN</td>
                <td className="tds">
                  <a
                    href="https://www.herox.com/SolarDesalination/round/589/entry/29432"
                    class="has-text-bright"
                  >
                    Solar Aqua Flex: Off-Grid Solar Still
                  </a>
                </td>
                <td class="has-text-centered">
                  <Button
                    color="primary"
                    id="tog18"
                    style={{ marginBottom: "1rem" }}
                  >
                    Play
                  </Button>
                </td>
              </tr>
              <div className="yt">
                <UncontrolledCollapse toggler="#tog17">
                  <div>
                    <iframe
                      width="100%"
                      height="444px"
                      src="https://www.youtube.com/embed/nL4VCPwxG_k?autoplay=1&mute=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </UncontrolledCollapse>
              </div>
            </tbody>
          </table>
        </div>
        <div className="container">
          <h1 className="mac_h1">Contests & Important Dates</h1>
          <StaticImage src="../../images/solardes3.jpg" className="img-styl" alt="solardes" loading="lazy" />
          <hr />
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>
              <b>Innovation Contest</b>
            </li>
            <ul>
              <li>Open: April 28, 2020</li>
              <li>Close: July 16, 2020, at 3 p.m. ET</li>
              <li>Quarterfinalists announced Oct. 19, 2020</li>
              <li>
                Review the technical specifications that teams had to consider
                during the Innovation Contest
              </li>
            </ul>
          </ul>
          <hr />
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>
              <b>Teaming Contest</b>
            </li>
            <ul>
              <li>Open: Oct. 19, 2020</li>
              <li>Close: Feb. 9, 2021, at 3 p.m. ET</li>
              <li>Semifinalists will be announced April 12, 2021</li>
            </ul>
          </ul>
          <hr />
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>
              <b>Design Contest</b>
            </li>
            <ul>
              <li>Open: April 12, 2021</li>
              <li>Close: Feb. 9, 2021, at 3 p.m. ET</li>
              <li>Semifinalists will be announced April 12, 2021</li>
              <li>Close: April 2024</li>
            </ul>
          </ul>
          <hr />
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>
              <b>Test Contest</b>
            </li>
            <ul>
              <li>Open: April 12, 2021</li>
              <li>Submissions accepted: The second Monday in April annually</li>
              <li>
                Winners are expected to be announced one month after the DOE
                site visit
              </li>
            </ul>
          </ul>
          <hr />
          <p>
            <a href="https://www.herox.com/SolarDesalination">
              Follow the prize
            </a>{" "}
            to be updated on future dates and the official rules.
          </p>
          <b>
            All dates are subject to change including contest openings,
            deadlines, and announcements.
          </b>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps"> Solutions that help shape the future </h3>
          <div className="col">
            <StaticImage
              src="../../images/solar-desal2.jpg"
              className="img-styl"
              alt="solar desal"
              loading="lazy"
            />
          </div>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/SolarDesalinationRound1">
              <button type="button" className="btns">
                Follow Round 1 Teams
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default SolardesalinationRn2
